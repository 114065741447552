export default function InfoIcon(props) {
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            width={38}
            height={38}
            {...props}
        >
            <circle
                cx={19}
                cy={19}
                r={18.845}
                style={{
                    fill: "none",
                    stroke: "#000",
                    strokeWidth: 0.30967322,
                    strokeMiterlimit: 10,
                }}
            />
            <path d="M15.643 14.54h6.496l-3.357 14.503h2.785l-.655 2.886H14.72l3.357-14.502h-3.122zm3.207-6.125c0-.301.059-.586.176-.856a2.2 2.2 0 0 1 .479-.697 2.38 2.38 0 0 1 .704-.47c.268-.118.56-.177.874-.177.302 0 .586.06.856.177.27.117.5.274.697.47.195.197.353.428.47.697.118.268.177.555.177.856 0 .302-.059.59-.177.864a2.432 2.432 0 0 1-.47.722 2.23 2.23 0 0 1-.697.495 2.018 2.018 0 0 1-.856.185c-.313 0-.604-.062-.874-.185a2.31 2.31 0 0 1-.704-.495 2.366 2.366 0 0 1-.479-.722 2.167 2.167 0 0 1-.176-.864z" />
        </svg>
    );
}