import { BrowserRouter, Route, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage';
import InfoPage from './pages/InfoPage';

import './App.css';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/info" element={<InfoPage/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
