import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useWindowSize } from 'usehooks-ts';

export default function InfoPage() {
    const { width } = useWindowSize();
    const isMobile = (width < 640);
    const isDesktop = !isMobile;

    const navigate = useNavigate();

    const onBackClick = () => {
        navigate("/");
    }

    return(
        <div className="flex flex-col w-full min-h-screen items-stretch p-6 sm:p-10 gap-6 bg-latte">
            <div className="flex items-start justify-between gap-6 mb-0 sm:mb-6">
                <button className="w.9.5 grow-0" onClick={onBackClick}>
                    <ArrowLeftIcon className="w-9.5 h-9.5 text-black"/>
                </button>
                <h1 className="font-orpheus-pro italic text-4xl sm:text-7xl text-black leading-120 text-center grow">Of sky and sea</h1>
                <div className="w-9.5 grow-0">&nbsp;</div>
            </div>
            <div className="flex flex-col items-stretch gap-6 text-center sm:max-w-[1200px] sm:mx-auto">
                <p className="font-orpheus-pro text-black text-base leading-120">
                    <span className="font-orpheus-pro italic">Of sky and sea</span> was presented on the lands of the Wurundjeri Woi-wurrung and the 
                    Bunurong Boon Wurrung peoples of the Eastern Kulin. The artists acknowledge the Traditional Custodians of these lands and waterways, 
                    and pay their respects to Elders, past, present, and emerging.
                </p>
                <h4 className="font-orpheus-pro text-black text-base leading-120 font-bold">ABOUT</h4>
                <p className="font-orpheus-pro text-black text-base leading-120">
                    <span className="font-orpheus-pro italic">Of sky and sea</span> considers the interconnected impacts facilitated by maritime ports as sites of 
                    colonial expansion and globalisation, and the ongoing connections and disconnections resulting from these endeavours, with language acting as a 
                    key marker of this process. 
                </p>
                <p className="font-orpheus-pro text-black text-base leading-120">
                    Using the discontinued maritime language of Morse code signal lights, 
                    <span className="font-orpheus-pro italic">Of sky and sea</span> took the form of a large-scale light installation in the middle of 
                    Docklands harbour, beaming a list of endangered and dormant languages up into the night sky over a four-night period. 
                    The transient light from this transmission existed momentarily on Earth before travelling out into spacetime forever.
                </p>
                <p className="font-orpheus-pro text-black text-base leading-120">
                    Once a language is forgotten it becomes a cipher, a code, indecipherable unless that knowledge is regained. In an act of mourning, 
                    this coded message was projected into the future as an echo of what has and is being lost.
                </p>
                <p className="font-orpheus-pro text-black text-base leading-120">
                    This website mirrors the Morse code signal as it journeys into the universe, each language listed with their geographic locality and vulnerability status. 
                    The website will terminate the moment the signal leaves the outer limits solar system, 1.87 years after the original transmission was sent, in June 2025.
                </p>
                <p className="font-orpheus-pro text-black text-base leading-120">
                    A full list of languages used in the project is available  <a className="underline text-blue" href="https://www.ofskyandsea.au/languages">here</a>. 
                    This data has been sourced from the <a className="underline text-blue" href="https://www.endangeredlanguages.com/#/4/29.147/19.094/0/100000/0/low/mid/high/unknown">Endangered Languages Catalogue</a>, a global project mapping the world's endangered languages.
                </p>
                <p className="font-orpheus-pro text-black text-base leading-120">
                    The installation component of the work was viewable live in the Docklands Harbour between 7-10pm, 17-20 August 2023.
                </p>
                <h4 className="font-orpheus-pro text-black text-base leading-120 font-bold">CREDITS</h4>
                <p className="font-orpheus-pro text-black text-base leading-120">
                    Lead artist: <a className="underline text-blue" href="https://michaelagleave.com/">Michaela Gleave</a><br/>
                    Programmer: Warren Armstrong<br/>
                    Lighting Design: Bosco Shaw<br/>
                    Technical Production: Additive
                </p>
                <p className="font-orpheus-pro text-black text-base leading-120">
                    <span className="font-orpheus-pro italic">Of sky and sea</span> has been commissioned by <a className="underline text-blue" href="https://nowornever.melbourne.vic.gov.au/series/now-or-never-art-trail">Now or Never</a> in 
                    collaboration with <a className="underline text-blue" href="https://experimenta.org/">Experimenta</a>.  
                </p>
                <p className="font-orpheus-pro text-black text-base leading-120">
                    This project builds upon elements of the previous work 'Messages of Hope, Messages of Love' created in collaboration with Fausto Brusamolino.
                </p>
                {isDesktop === true && (
                    <div className="flex w-full justify-center items-center gap-20 mt-10 mb-5">
                        <img src="/logo_city_melb.png" className="h-14" alt="City of Melbourne Logo"/>
                        <img src="/logo_vic_gov.png" className="h-14" alt="Victoria Government Logo"/>
                        <img src="/logo_experimenta.png" className="h-10" alt="Experimenta Logo"/>
                    </div>
                )}
                {isMobile === true && (
                    <div className="flex flex-col items-center gap-10 mt-10 mb-5">
                        <div className=" flex justify-center items-center gap-10">
                            <img src="/logo_city_melb.png" className="h-14" alt="City of Melbourne Logo"/>
                            <img src="/logo_vic_gov.png" className="h-14" alt="Victoria Government Logo"/>
                        </div>
                        <img src="/logo_experimenta.png" className="h-10" alt="Experimenta Logo"/>
                    </div>
                )}
            </div>
        </div>
    );
}