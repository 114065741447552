import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

import InfoIcon from "../components/InfoIcon";

const DURATION = 2 * 60 * 1000;
const DISPLAY_REFRESH_TIME = 250;

const TIME_UNIT_MS = 250;

const TimeUnits = {
    DOT_TIME: 1,
    DASH_TIME: 3,
    SYMBOL_SPACE_TIME: 1,
    LETTER_SPACE_TIME: 2,
    WORD_SPACE_TIME: 7,
    MESSAGE_SPACE: 20   // 250 * 20 = 5 seconds
}

const Symbols = {
    DOT: '.',
    DASH: '-',
    SYMBOL_SPACE: ' ',
    LETTER_SPACE: ',',
    WORD_SPACE: '/'
}

const LIGHT_KM_PER_SEC = 299792.5;

var languages = [];
var loadCount = 0;
var lightDistance = 0;
var lightDistanceTime = 0;
var lightSpacemark = "";

export default function HomePage() {
    const { width } = useWindowSize();
    const isMobile = (width < 640);
    const isDesktop = !isMobile;

    const navigate = useNavigate();

    let timeOffset = 0;
    const [queryParameters] = useSearchParams();
    const testTime = queryParameters.get("time");
    if (testTime) {
        const tp = testTime.split("-").map(t => parseInt(t));
        const testDate = new Date(tp[0], tp[1] - 1, tp[2], tp[3], tp[4], 0, 0);
        const now = new Date();
        timeOffset = testDate.getTime() - now.getTime();
    }

    const [isLoading, setIsLoading] = useState(false);

    const [distance, setDistance] = useState("");
    const [spacemark, setSpacemark] = useState("");
    const [location, setLocation] = useState("");
    const [status, setStatus] = useState("");

    const [name, setName] = useState("Loading...");
    const [codeBefore, setCodeBefore] = useState([]);
    const [codeAfter, setCodeAfter] = useState([]);

    /* Display Update Functions */

    const getCurrentTime = () => {
        return new Date().getTime() + timeOffset;
    };

    const getSpacedCode = (code) => {
        let spacedWords = [];
        const codeWords = code.split(" / ");
        for (let w = 0; w < codeWords.length; w++) {
            const letters =  codeWords[w].split(" ");
            let spacedLetters = [];             
            for (let l = 0; l < letters.length; l++) {
                spacedLetters.push(letters[l].split("").join(" "));
            }
            const spacedWord = spacedLetters.join(",");
            spacedWords.push(spacedWord);
        }
        return spacedWords.join("/");
    }

    const getCharTime = (char) => {
        switch (char) {
            case Symbols.DOT:
                return TimeUnits.DOT_TIME * TIME_UNIT_MS;
            case Symbols.DASH:
                return TimeUnits.DASH_TIME * TIME_UNIT_MS;
            case Symbols.SYMBOL_SPACE:
                return TimeUnits.SYMBOL_SPACE_TIME * TIME_UNIT_MS;
            case Symbols.LETTER_SPACE:
                return TimeUnits.LETTER_SPACE_TIME * TIME_UNIT_MS;
            case Symbols.WORD_SPACE:
                return TimeUnits.WORD_SPACE_TIME * TIME_UNIT_MS;
            default:
                return 0;
        }
    }

    const getCharToAdd = (char) => {
        switch (char) {
            case Symbols.DOT:
                return Symbols.DOT;
            case Symbols.DASH:
                return Symbols.DASH;
            case Symbols.SYMBOL_SPACE:
                return '';
            case Symbols.LETTER_SPACE:
                return '&nbsp;';
            case Symbols.WORD_SPACE:
                return '&nbsp;&nbsp;';
            default:
                return '';
        }
    }
    /* Interaction Functions */

    const infoClicked = () => {
        navigate("/info");
    }

    /* useEffect Functions */

    useEffect(() => {
        let displayInterval;
        let loadInterval;

        const updateDisplay = () => {
            const current = getCurrentTime();
            const langs = languages.filter(l => l.end >= current);
            const cls = langs.filter(l => l.start <= current);
            if (cls.length > 0) {
                const cl = cls[0];

                let codeTime = cl.start;
                let spacedCode = getSpacedCode(cl.morseCode);
                let cBefore = [];
                let cAfter = [];
                for (let i = 0; i < spacedCode.length; i++) {
                    const char = spacedCode[i];
                    if (codeTime <= current) {
                        cBefore.push(getCharToAdd(char));
                    } else {
                        cAfter.push(getCharToAdd(char));
                    }
                    codeTime = codeTime + getCharTime(char);
                }
                let dist = Math.round(lightDistance + (((current - lightDistanceTime) / 1000) * LIGHT_KM_PER_SEC));
    
                setDistance(dist.toLocaleString() + " km");
                setSpacemark(lightSpacemark);
                setName(cl.siteName);
                setLocation(cl.location);
                setStatus(cl.status);
                setCodeBefore(cBefore);
                setCodeAfter(cAfter);
            }
            languages = langs;
        };

        const loadLanguages = () => {
            if (!isLoading) {
                loadCount = loadCount + 1;
                setIsLoading(true);
                const url = `https://api.ofskyandsea.net/language/timed?time=${getCurrentTime()}&duration=${DURATION}&start=split&include=distance`;
                axios.get(url)
                .then(res => {
                    setIsLoading(false);
                    let data = res.data;
                    let langs = [];
                    if (languages.length > 0) {
                        const starts = languages.map(l => l.start);
                        const newlangs = data.languages.filter(l => starts.indexOf(l.start) === -1);
                        langs = languages.concat(newlangs);
                    } else {
                        langs = data.languages;
                    }
                    langs.sort((a, b) => { return a.start - b.start});
            
                    if (loadCount === 1) {
                        loadInterval = setTimeout(loadLanguages, DURATION / 2);
                    } else if (loadCount === 2) {
                        loadInterval = setInterval(loadLanguages, DURATION);
                    }
            
                    languages = langs;
                    lightDistance = data.lightDistance;
                    lightDistanceTime = data.start;
                    lightSpacemark = data.spacemark;
                })
                .catch(err => {
                    setIsLoading(false);
                    console.log(err);
                });
            }
        };
    
        displayInterval = setInterval(updateDisplay, DISPLAY_REFRESH_TIME);
        if (loadCount === 0) {
            loadLanguages();
        }

        return () => {
            clearInterval(displayInterval);
            clearInterval(loadInterval);
        }
    }, []);

    return(
        <>
        {isDesktop === true && (
            <div className="flex w-full min-h-screen flex-col items-center justify-between p-10 bg-latte">
                <div className="flex justify-between items-start w-full">
                    <div className="flex flex-col gap-1.5">
                        <p className="font-orpheus-pro text-xl text-black leading-120 text-left">Signal Has Travelled:</p>
                        <p className="font-orpheus-pro text-xl text-black leading-120 text-left">{distance}</p>
                        <p className="font-orpheus-pro text-base text-black leading-120 text-left">{spacemark}</p>
                    </div>
                    <button onClick={(e) => infoClicked()}>
                        <InfoIcon 
                            alt="Info button"
                        />
                    </button>
                </div>
                <div className="flex flex-col items-center justify-center px-1/10">
                    <h1 className="font-orpheus-pro italic text-7xl text-black leading-120">{name}</h1>
                    <div className="flex items-start justify-center flex-wrap">
                        {codeBefore.map((c, index) => (
                            <p 
                                key={"char-before-" + index} 
                                className="font-orpheus-pro italic text-7xl text-black leading-120" 
                                dangerouslySetInnerHTML={{__html: c}}
                            />
                        ))}
                        {codeAfter.map((c, index) => (
                            <p 
                                key={"char-after-" + index} 
                                className="font-orpheus-pro italic text-7xl text-blue leading-120" 
                                dangerouslySetInnerHTML={{__html: c}}
                            />
                        ))}
                    </div>
                </div>
                <div className="w-full flex items-end justify-between">
                    <p className="font-orpheus-pro text-xl text-black leading-6 text-left">{"Location: " + location}</p>
                    <p className="font-orpheus-pro text-xl text-black leading-6 text-right">{"Status: " + status}</p>
                </div>
            </div>
        )}
        {isMobile === true && (
            <div className="flex w-full min-h-screen flex-col items-center justify-between p-6 bg-latte">
                <div className="flex justify-between items-start w-full">
                    <div className="flex flex-col gap-2">
                        <p className="font-orpheus-pro text-base text-black leading-120 text-left">Signal Has Travelled:</p>
                        <p className="font-orpheus-pro text-base text-black leading-120 text-left">{distance}</p>
                        <p className="font-orpheus-pro text-xs text-black leading-120 text-left">{spacemark}</p>
                    </div>
                    <button onClick={(e) => infoClicked()}>
                        <InfoIcon 
                            alt="Info button"
                        />
                    </button>
                </div>
                <div className="flex flex-col items-center justify-center px-1/10">
                    <h1 className="font-orpheus-pro italic text-4xl text-black leading-120">{name}</h1>
                    <div className="flex items-start justify-center flex-wrap">
                        {codeBefore.map((c, index) => (
                            <p 
                                key={"char-before-" + index} 
                                className="font-orpheus-pro italic text-4xl text-black leading-120" 
                                dangerouslySetInnerHTML={{__html: c}}
                            />
                        ))}
                        {codeAfter.map((c, index) => (
                            <p 
                                key={"char-after-" + index} 
                                className="font-orpheus-pro italic text-4xl text-blue leading-120" 
                                dangerouslySetInnerHTML={{__html: c}}
                            />
                        ))}
                    </div>
                </div>
                <div className="w-full flex items-end justify-between">
                    <div className="flex flex-col items-start gap-1.5">
                        <p className="font-orpheus-pro text-base text-black leading-120 text-left">Location:</p>
                        <p className="font-orpheus-pro text-base text-black leading-120 text-left">{location}</p>
                    </div>
                    <div className="flex flex-col items-end gap-1.5">
                        <p className="font-orpheus-pro text-base text-black leading-120 text-right">Status:</p>
                        <p className="font-orpheus-pro text-base text-black leading-120 text-right">{status}</p>
                    </div>
                </div>
            </div>
        )}
        </>
    );
}